<template>
	<span class="error error-message error-icon" v-for="error in errors" :key="error">
		<i class="fa-solid fa-circle-xmark"></i>
	</span>
</template>

<script>
export default {
	name: "Errors",
	props: ["errors"],
}
</script>
<style scoped></style>
